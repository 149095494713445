import { forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Check Auth
import { isUserLoggedIn } from "./../../../../@core/auth/utils";

// @Utility
// import alertText from "./../../../../@utility/app/alertText";

// Redux
import { setDataDialog } from "./../../../../@core/redux/actions/app/dialog";
import { setTypeAll, setPopupGameData } from "./../../../../@core/redux/actions/game/index";
// import { setDataAlert } from "./../../../../@core/redux/actions/app/alert";

// Services
// import { openGame as loadLinkGame } from "./../../../../@core/services/gameService";

const OpenGame = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const homeData = useSelector(state => state.reduxHome.result);

    useImperativeHandle(ref, () => ({
        loadGame(data, typeLink) {
            const item = data;
            let promotion_status = 0;
            // let snackbarJson = "";

            if(homeData !== null) {
                promotion_status = homeData.promotion_status;
            }

            const gotoGame = () => {
                if (isUserLoggedIn()) {
                    if (item?.game_code === undefined) {
                        dispatch(setPopupGameData({
                            show: true,
                            camp: item.camp.toLowerCase(), 
                            gamecode: item.code
                        }));
                    }
                    else {
                        dispatch(setPopupGameData({
                            show: true,
                            camp: item.camp.toLowerCase(), 
                            gamecode: item.game_code
                        }));
                    }
                }
                else {
                    let linkPath = "";
                    if (item?.game_code === undefined) {
                        linkPath = `/games/camp/${item.camp.toLowerCase()}/${item.code}?play_game=true&redirect=home`;
                    }
                    else {
                        linkPath = `/games/camp/${item.camp.toLowerCase()}/${item.game_code}?play_game=true&redirect=home`;
                    }

                    openBox(linkPath);
                }
            };

            const gotoCamp = () => {
                // if(item.open === 2) {
                //     dispatch(setProcessingGame(true));
                //     const camp = item.camp.toLowerCase();
                //     const game_code = item.game_code;

                //     loadLinkGame(camp, game_code).then((data) => {
                //         dispatch(setProcessingGame(false));
                //         const statusCode = data.code;
                //         if (statusCode === 0) {
                //             if (isUserLoggedIn()) {
                //                 window.open(data.result.url);
                //             }
                //             else {
                //                 openBox(data.result.url);
                //             }
                //         }
                //         else {
                //             if (statusCode === 2 || statusCode === 4) {
                //                 snackbarJson = alertText(statusCode);
                //                 openAlert(snackbarJson);
            
                //                 setTimeout(function () {
                //                     window.location.href = `/?web=${process.env.REACT_APP_DEFAULT_AGENT_CODE}`;
                //                 }, 5000);
                //             }
                //             else {
                //                 snackbarJson = alertText(statusCode);
                //                 openAlert(snackbarJson);
                //             }
                //         }
                //     });
                // }
                // else {
                if (item.game_code === "" || item.game_code === null) {
                    if (item.types !== null) {
                        dispatch(setTypeAll({
                            type: item.types,
                            specials: item.specials
                        }));
                    }
                    else {
                        dispatch(setTypeAll(null));
                    }
        
                    history.push(`/games/camp/${item.code}`);
                }
                else {
                    // if(item.status === 2 && promotion_status !== 1) {
                    //     let linkPath = `/games/camp/${item.camp.toLowerCase()}/${item.game_code}?play_game=true`;
                    //     if (isUserLoggedIn()) {
                    //         history.push(linkPath);
                    //     }
                    //     else {
                    //         openBox(linkPath);
                    //     }
                    // }
                    if (isUserLoggedIn()) {
                        // history.push(linkPath);
                        dispatch(setPopupGameData({
                            show: true,
                            camp: item.camp.toLowerCase(), 
                            gamecode: item.game_code
                        }));
                    }
                    else {
                        let linkPath = `/games/camp/${item.camp.toLowerCase()}/${item.game_code}?play_game=true&redirect=home`;
                        openBox(linkPath);
                    }
                }
                // }
            };
        
            const openBox = (link) => {
                // let dialogJson = {};
                // if(item.open === 2) {
                //     dialogJson = {
                //         pageType: "newTabGame",
                //         statusShow: true,
                //         type: "html",
                //         image: `${window.location.protocol}//${window.location.host}/images/icon/bell.png`,
                //         title: "โหมดทดลองเล่นเกม",
                //         content: `ยอดที่ได้รับจะไม่สามารถนำถอนเงินได้ หากต้องการวางเดิมพันกรุณา`,
                //         action: link
                //     };
                // }
                // else {
                //     dialogJson = {
                //         pageType: "openGame",
                //         statusShow: true,
                //         type: "html",
                //         image: `${window.location.protocol}//${window.location.host}/images/icon/bell.png`,
                //         title: "โหมดทดลองเล่นเกม",
                //         content: `ยอดที่ได้รับจะไม่สามารถนำถอนเงินได้ หากต้องการวางเดิมพันกรุณา`,
                //         action: link
                //     };
                // }

                let dialogJson = {
                    pageType: "openGame",
                    statusShow: true,
                    type: "html",
                    image: `${window.location.protocol}//${window.location.host}/images/icon/bell.png`,
                    title: "โหมดทดลองเล่นเกม",
                    content: `ยอดที่ได้รับจะไม่สามารถนำถอนเงินได้ หากต้องการวางเดิมพันกรุณา`,
                    action: link
                };
        
                dispatch(setDataDialog(dialogJson));
            };

            // const openAlert = (snackbarJson) => {
            //     dispatch(setDataAlert({
            //         type: snackbarJson.statusAlert,
            //         title: snackbarJson.titleAlert,
            //         text: snackbarJson.textAlert,
            //         action: true,
            //         redirect: null,
            //         show: true,
            //         buttonText: "ตกลง"
            //     }));
            // };

            if(typeLink === "camp") {
                gotoCamp();
            }
            else if(typeLink === "path") {
                history.push(item);
            }
            else {
                gotoGame();
            }
        }
    }));

    return null;
});

export default OpenGame;